import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import galacticaimg from "../../images/galactica.png";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import customDarcula from '../../styles/customDarcula';
import { snapshots } from '../../components/Chains/snapshots';
import { peersGalacticatest } from '../../components/Peers/peersGalacticatest';

const ServiceGalacticatest = () => {

  const [version, setVersion] = useState('');
  const [size, setSize] = useState('');
  const [block, setBlock] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [file, setFile] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const myFetch = async () => {
      let response = await fetch('https://testnet.ciento.exchange/snapshots');
      let data = await response.json();
      setVersion(data[0]["galactica_testnet"].version);
      setSize(data[0]["galactica_testnet"].size)
      setBlock(data[0]["galactica_testnet"].block);
      setTimestamp(data[0]["galactica_testnet"].timestamp);
      setFile(data[0]["galactica_testnet"].file);
      setLink(data[0]["galactica_testnet"].link);
    }

    myFetch()
      .catch(console.error);  

  }, []);
   
  const [toolsMenu, setToolsMenu] = useState(true);
  const [isBackVisible, setBack] = useState(true);
  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible8, setBelowVisible8] = useState(false);
  const [isBelowVisible9, setBelowVisible9] = useState(false);
  const [isBelowVisible10, setBelowVisible10] = useState(false);
  const codeRef = useRef(null);

  const peersOne = peersGalacticatest['peers'];
  const peersTwo = `PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.galactica/config/config.toml`;


const snapOne = `cd $HOME
sudo systemctl stop galacticad
cp $HOME/.galactica/data/priv_validator_state.json $HOME/.galactica/priv_validator_state.json.backup
rm -rf $HOME/.galactica/data
wget -O ` + file + ` ` + link + `
lz4 -c -d ` + file + ` | tar -x -C $HOME/.galactica
mv $HOME/.galactica/priv_validator_state.json.backup $HOME/.galactica/data/priv_validator_state.json
sudo systemctl restart galacticad`

  const addrbook = `wget -O addrbook.json https://support.synergynodes.com/addrbook/galactica_testnet/addrbook.json --inet4-only
mv addrbook.json $HOME/.galactica/config`;

  const genesis = `wget -O genesis.json https://support.synergynodes.com/genesis/galactica_testnet/genesis.json --inet4-only
mv genesis.json $HOME/.galactica/config`;



const nodeInstallUser = `sudo apt update
sudo apt-get install git curl build-essential make jq gcc snapd chrony lz4 tmux unzip bc -y`;

const nodeInstallGo = `# Install Go
cd $HOME
curl https://dl.google.com/go/go1.21.3.linux-amd64.tar.gz | sudo tar -C/usr/local -zxvf -
  
# Update environment variables to include go
cat <<'EOF' >>$HOME/.profile
export GOROOT=/usr/local/go
export GOPATH=$HOME/go
export GO111MODULE=on
export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin
EOF
  
source $HOME/.profile
  
# check go version
go version`;


const nodeInstallNode = `# Install Galactica Testnet node
cd $HOME
wget https://github.com/Galactica-corp/galactica/releases/download/` + version + `/galacticad_` + version + `-linux_amd64.zip
unzip galacticad_` + version + `-linux_amd64.zip
chmod +x $HOME/galacticad
sudo mv $HOME/galacticad $HOME/go/bin
cd $HOME`;


const nodeInstallInitialize = `# Initialize Node
galacticad init <moniker> --chain-id galactica_9302-1

# Example:
galacticad init Synergy_Nodes --chain-id galactica_9302-1`;

const nodeInstallGenesis = `curl -Ls https://support.synergynodes.com/genesis/galactica_testnet/genesis.json > $HOME/.galactica/config/genesis.json`;

const nodeInstallAddrbook = `curl -Ls https://support.synergynodes.com/addrbook/galactica_testnet/addrbook.json > $HOME/.galactica/config/addrbook.json`;

const nodeInstallPeers = `# Add / Update Peers
PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.galactica/config/config.toml`;

const nodeInstallService = `# Create Service
sudo tee /etc/systemd/system/galacticad.service > /dev/null <<EOF
[Unit]
Description=galacticad Daemon
#After=network.target
StartLimitInterval=350
StartLimitBurst=10

[Service]
Type=simple
User=node
ExecStart=/home/node/go/bin/galacticad start
Restart=on-abort
RestartSec=30

[Install]
WantedBy=multi-user.target

[Service]
LimitNOFILE=1048576
EOF`;

const nodeInstallSnapshot = `# Follow these commands
` + snapOne;

const nodeInstallStart = `# Enable service
sudo systemctl enable galacticad

# Start service
sudo service galacticad start

# Check logs
sudo journalctl -fu galacticad`;



const statesyncURL = `https://galactica-testnet-rpc.synergynodes.com:443`;

const statesync1 = `#!/bin/bash

SNAP_RPC="https://galactica-testnet-rpc.synergynodes.com:443"
LATEST_HEIGHT=$(curl -s $SNAP_RPC/block | jq -r .result.block.header.height); \\
BLOCK_HEIGHT=$((LATEST_HEIGHT - 2000)); \\
TRUST_HASH=$(curl -s "$SNAP_RPC/block?height=$BLOCK_HEIGHT" | jq -r .result.block_id.hash)
echo $LATEST_HEIGHT $BLOCK_HEIGHT $TRUST_HASH

sed -i.bak -E "s|^(enable[[:space:]]+=[[:space:]]+).*$|\\1true| ; \\
s|^(rpc_servers[[:space:]]+=[[:space:]]+).*$|\\1\\"$SNAP_RPC,$SNAP_RPC\\"| ; \\
s|^(trust_height[[:space:]]+=[[:space:]]+).*$|\\1$BLOCK_HEIGHT| ; \\
s|^(trust_hash[[:space:]]+=[[:space:]]+).*$|\\1\\"$TRUST_HASH\\"|" $HOME/.galactica/config/config.toml
more ~/.galactica/config/config.toml | grep 'rpc_servers'
more ~/.galactica/config/config.toml | grep 'trust_height'
more ~/.galactica/config/config.toml | grep 'trust_hash'
`;

const statesyncTwo = `chmod 700 state_sync.sh
./state_sync.sh`;

const statesyncThree = `sudo service galacticad stop`;

const statesyncFour = `galacticad tendermint unsafe-reset-all --home $HOME/.galactica --keep-addr-book`;

const statesyncFive = `sudo service galacticad start`;


const seednodeOne = `7156dae029b1075d1468bff7a0062a2b89adcbba@galactica-testnet-seed.synergynodes.com:13656`;

const seednodeTwo = `seed="7156dae029b1075d1468bff7a0062a2b89adcbba@galactica-testnet-seed.synergynodes.com:13656"
sed -i.bak -e "s/^seed *=.*/seed = \\"$seed\\"/" ~/.galactica/config/config.toml`;

const seednodeThree = `sudo systemctl restart galacticad
journalctl -u galacticad -f`;



  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   
  const toggleBelow8 = () => {
    setBelowVisible8(!isBelowVisible8);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow9 = () => {
    setBelowVisible9(!isBelowVisible9);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };   
  const toggleBelow10 = () => {
    setBelowVisible10(!isBelowVisible10);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  
  
  const toggleBack = () => {
    setBelowVisible1(false);
    setBelowVisible8(false);
    setBelowVisible2(false);
    setBelowVisible3(false);
    setBelowVisible4(false);
    setBelowVisible5(false);
    setBelowVisible7(false);
    setBelowVisible9(false);
    setBelowVisible10(false);
    setBack(!isBackVisible);
    setToolsMenu(true);
  };

  return (
    <>
    <Header/>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={galacticaimg}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Galactica Testnet
            <span className="myurl"><a href="https://galactica.com" target="_blank">https://galactica.com</a></span>
          </div>
        </div>
        
        


      <div className="myStake">
        <a href="https://testnets.cosmosrun.info/galactica/staking/galavaloper17mqfhh3gfe5s7k0ejllwkxf3e7edejn89wjnh3" target="_blank">Stake with Synergy Nodes</a>
      </div>
      
      <hr className="myhr"></hr>
  
      {toolsMenu && (
        <div className="toolBoxMain">     
          <div className="toolBox" onClick={toggleBelow9}>
            Explorer
          </div>          
          <div className="toolBox" onClick={toggleBelow1}>
            Node Snapshot
          </div>
          <div className="toolBox" onClick={toggleBelow10}>
            State Sync
          </div>           
          <div className="toolBox" onClick={toggleBelow8}>
            Node Installation
          </div>            
          <div className="toolBox" onClick={toggleBelow2}>
            Public Endpoints
          </div>          
          <div className="toolBox" onClick={toggleBelow3}>
            Persistent Peers
          </div>  
          <div className="toolBox" onClick={toggleBelow4}>
            AddrBook File
          </div>   
          <div className="toolBox" onClick={toggleBelow5}>
            Genesis File
          </div>  
          <div className="toolBox" onClick={toggleBelow7}>
            Seed Node
          </div>          
        </div>
      )}
  
      {!isBackVisible && (
        <div className="toolBoxMain">
          <div className="toolBoxBack" onClick={toggleBack}>
            Go Back
          </div>
        </div>
      )}             
  

          {isBelowVisible9 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Explorer</div>
            </div> 
              <div className="below"><a href="https://testnets.cosmosrun.info/galactica">https://testnets.cosmosrun.info/galactica</a></div>         
            </div>
          )}




          {isBelowVisible1 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Snapshot</div>
            </div>             
            <div className="below">
              <div className="snap">
                <b>Type:</b> Pruned Snapshot <br />
                <b>Version:</b> galacticad {version} <br />
                <b>Block:</b> {block} <br />
                <b>Size:</b> {size} <br />
                <b>Timestamp:</b> {timestamp} Hours ago <br />
              </div>
            
              <br></br>
              <b>Follow these commands</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {snapOne}
              </SyntaxHighlighter>           

            </div>
            </div>
          )}



          {isBelowVisible10 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">State Sync</div>
            </div>
              <div className="below">
                Our state-sync RPC server
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {statesyncURL}
                </SyntaxHighlighter>
                Create a shell script file with the name <b>state_sync.sh</b> and add the following code. This will fetch the details about state sync block height and hash from our server and will update this information on your <b>config1.toml</b>.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {statesync1}
                </SyntaxHighlighter>
                Grant execute permission to the script and run the script.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {statesyncTwo}
                </SyntaxHighlighter>
                Stop the node.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {statesyncThree}
                </SyntaxHighlighter>   
                Reset the node.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {statesyncFour}
                </SyntaxHighlighter>  
                Start the node.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {statesyncFive}
                </SyntaxHighlighter>                                               
              </div>
            </div>
          )}




          {isBelowVisible8 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Installation</div>
            </div>             
            <div className="below">
              
              <br />
              <b>Login as User and install required tools</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallUser}
              </SyntaxHighlighter>              
              
              <br />
              <b>Install Go</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallGo}
              </SyntaxHighlighter>

              <br />
              <b>Install the Node</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallNode}
              </SyntaxHighlighter>

              <br />
              <b>Initialize the Node</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallInitialize}
              </SyntaxHighlighter>              
              
              <br />
              <b>Download Genesis file</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallGenesis}
              </SyntaxHighlighter>    
              
              <br />
              <b>Download Addrbook file</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallAddrbook}
              </SyntaxHighlighter>    
              
              <br />
              <b>Add / Update Persistent Peers</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallPeers}
              </SyntaxHighlighter> 
              
              <br />
              <b>Download & decompress Snapshot</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallSnapshot}
              </SyntaxHighlighter>                 
              
              <br />
              <b>Create Service File</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallService}
              </SyntaxHighlighter>     
              
              <br />
              <b>Start the Node</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallStart}
              </SyntaxHighlighter>                 

            </div>
            </div>
          )}
      



          {isBelowVisible2 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Public Endpoints</div>
            </div> 
              <div className="below"><a href="https://galactica-testnet-api.synergynodes.com/">https://galactica-testnet-api.synergynodes.com/</a></div>
              <div className="belowtext">
              <a href="https://galactica-testnet-rpc.synergynodes.com/">https://galactica-testnet-rpc.synergynodes.com/</a>
              </div>
              <div className="belowtext">
              <a href="https://galactica-testnet-grpc.synergynodes.com/">https://galactica-testnet-grpc.synergynodes.com/</a>
              </div>              
            </div>
          )}





          {isBelowVisible3 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Persistent Peers</div>
            </div> 
              <div className="below">
                The persistent peers list is updated every day. Here is the list
                of persistent peers.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {peersOne}
                </SyntaxHighlighter>
                You can use the following to update the peers in config.toml file.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {peersTwo}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible4 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Addrbook File</div>
            </div> 
              <div className="below">
              The Addrbook is updated once every week. Stop the node, download addrbook.json file, replace it and restart the node.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                  {addrbook}
                </SyntaxHighlighter>
              </div>
            </div>
          )}  
        

   
          {isBelowVisible5 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Genesis File</div>
            </div> 
              <div className="below">
              We recommend you to download the genesis.json file from Galactica official Github page. However, we also provide the same copy of the genesis.json file. You can download and replace the genesis.json file using the following commands.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                  {genesis}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible7 && (
            <div className="node-box" style={{ height: "100%" }}>
                <div className="upper">
                <div className="right">Seed Node</div>
                </div>
                <div className="below">
                <br />
                <center><b>{seednodeOne}</b></center>
                <br />
                Set Seed Node:
                  <SyntaxHighlighter language="shell" style={customDarcula}>
                    {seednodeTwo}
                  </SyntaxHighlighter>
                  <br />
                  Restart Node:
                    <SyntaxHighlighter language="shell" style={customDarcula}>
                      {seednodeThree}
                    </SyntaxHighlighter>                  
                </div>                
            </div>
          )}          


      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServiceGalacticatest;
