// src/styles/theme.js
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";

const customDarcula = {
  ...darcula,
  'pre[class*="language-"]': {
    ...darcula['pre[class*="language-"]'],
    background: '#121314', // Change background to black
    lineHeight: '1.8em', // Adjust line height
    border: '2px solid #313233',
    borderRadius: '5px'
  }
};

export default customDarcula;