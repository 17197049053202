import "../../styles/Services/ServicePage.scss";
import Header from "../Header";
import Footer from "../Footer";
import image from "../../images/test1.png";
import { useState, useEffect, useRef } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import customDarcula from '../../styles/customDarcula';
import { snapshots } from '../../components/Chains/snapshots';
import { peersElystest } from '../../components/Peers/peersElystest';

const ServiceElystest = () => {

  const [version, setVersion] = useState('');
  const [size, setSize] = useState('');
  const [block, setBlock] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const [file, setFile] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const myFetch = async () => {
      let response = await fetch('https://testnet.ciento.exchange/snapshots');
      let data = await response.json();
      setVersion(data[0]["elys_testnet"].version);
      setSize(data[0]["elys_testnet"].size)
      setBlock(data[0]["elys_testnet"].block);
      setTimestamp(data[0]["elys_testnet"].timestamp);
      setFile(data[0]["elys_testnet"].file);
      setLink(data[0]["elys_testnet"].link);
    }

    myFetch()
      .catch(console.error);  

  }, []);

    
  const [toolsMenu, setToolsMenu] = useState(true);
  const [isBackVisible, setBack] = useState(true);

  const [isBelowVisible1, setBelowVisible1] = useState(false);
  const [isBelowVisible2, setBelowVisible2] = useState(false);
  const [isBelowVisible3, setBelowVisible3] = useState(false);
  const [isBelowVisible4, setBelowVisible4] = useState(false);
  const [isBelowVisible5, setBelowVisible5] = useState(false);
  const [isBelowVisible6, setBelowVisible6] = useState(false);
  const [isBelowVisible7, setBelowVisible7] = useState(false);
  const [isBelowVisible8, setBelowVisible8] = useState(false);
  const codeRef = useRef(null);

  const peersOne = peersElystest['peers'];
  const peersTwo = `PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.elys/config/config.toml`;


const snapOne = `cd $HOME
sudo systemctl stop elysd
cp $HOME/.elys/data/priv_validator_state.json $HOME/.elys/priv_validator_state.json.backup
rm -rf $HOME/.elys/data
wget -O ` + file + ` ` + link + `
lz4 -c -d ` + file + ` | tar -x -C $HOME/.elys
mv $HOME/.elys/priv_validator_state.json.backup $HOME/.elys/data/priv_validator_state.json
sudo systemctl restart elysd`

  const addrbook = `wget -O addrbook.json https://support.synergynodes.com/addrbook/elys_testnet/addrbook.json --inet4-only
mv addrbook.json $HOME/.elys/config`;

  const genesis = `wget -O genesis.json https://support.synergynodes.com/genesis2/elys_testnet/genesis.json --inet4-only
mv genesis.json $HOME/.elys/config`;




const nodeInstallUser = `sudo apt update
sudo apt-get install git curl build-essential make jq gcc snapd chrony lz4 tmux unzip bc -y`;

const nodeInstallGo = `# Install Go
cd $HOME
curl https://dl.google.com/go/go1.22.5.linux-amd64.tar.gz | sudo tar -C/usr/local -zxvf -
  
# Update environment variables to include go
cat <<'EOF' >>$HOME/.profile
export GOROOT=/usr/local/go
export GOPATH=$HOME/go
export GO111MODULE=on
export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin
EOF
  
source $HOME/.profile
  
# check go version
go version`;


const nodeInstallNode = `# Install Elys Testnet node
git clone https://github.com/elys-network/elys elys
cd elys
git checkout `+ version +`
make install
cd
elysd version --long`;


const nodeInstallInitialize = `# Initialize Node
elysd init <moniker> --chain-id=elysicstestnet-1

# Example:
elysd init Synergy_Nodes --chain-id=elysicstestnet-1`;

const nodeInstallGenesis = `curl -Ls https://support.synergynodes.com/genesis2/elys_testnet/genesis.json > $HOME/.elys/config/genesis.json`;

const nodeInstallAddrbook = `curl -Ls https://support.synergynodes.com/addrbook/elys_testnet/addrbook.json > $HOME/.elys/config/addrbook.json`;

const nodeInstallPeers = `# Add / Update Peers
PEERS=` + peersOne + `
sed -i.bak -e "s/^persistent_peers *=.*/persistent_peers = \"$PEERS\"/" $HOME/.elys/config/config.toml`;

const nodeInstallService = `# Create Service
sudo tee /etc/systemd/system/elysd.service > /dev/null <<EOF
[Unit]
Description=Elysd Daemon
#After=network.target
StartLimitInterval=350
StartLimitBurst=10

[Service]
Type=simple
User=node
ExecStart=/home/node/go/bin/elysd start
Restart=on-abort
RestartSec=30

[Install]
WantedBy=multi-user.target

[Service]
LimitNOFILE=1048576
EOF`;

const nodeInstallSnapshot = `# Follow these commands
` + snapOne;

const nodeInstallStart = `# Enable service
sudo systemctl enable elysd

# Start service
sudo service elysd start

# Check logs
sudo journalctl -fu elysd`;




  const toggleBelow1 = () => {
    setBelowVisible1(!isBelowVisible1);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow2 = () => {
    setBelowVisible2(!isBelowVisible2);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow3 = () => {
    setBelowVisible3(!isBelowVisible3);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };
  const toggleBelow4 = () => {
    setBelowVisible4(!isBelowVisible4);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow5 = () => {
    setBelowVisible5(!isBelowVisible5);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };  
  const toggleBelow6 = () => {
    setBelowVisible6(!isBelowVisible6);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  };    
  const toggleBelow7 = () => {
    setBelowVisible7(!isBelowVisible7);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  }; 
  const toggleBelow8 = () => {
    setBelowVisible8(!isBelowVisible8);
    setToolsMenu(!toolsMenu);
    setBack(!isBackVisible);
  }; 
  
  const toggleBack = () => {
    setBelowVisible1(false);
    setBelowVisible8(false);
    setBelowVisible2(false);
    setBelowVisible3(false);
    setBelowVisible4(false);
    setBelowVisible5(false);
    setBelowVisible7(false);
    setBack(!isBackVisible);
    setToolsMenu(true);
  };

  return (
    <>
    <Header/>

    <div className="servicepage-back">
      <div className="servicepage">
        <div className="title">Tools & Services</div>
        <div className="text">
          At Synergy Nodes, we are committed to providing unmatched security and
          trust for blockchain networks as a reputable validator.
        </div>
        <div className="logo">
          <img src={image}></img>
          <div className="name" style={{ color: "#4DA2FF" }}>
            Elys (Testnet)
            <span className="myurl"><a href="https://elys.network" target="_blank">https://elys.network</a></span>
          </div>
        </div>



        <div className="myStake">
          <a href="https://testnet.elys.network/elys/staking/elysvaloper1xesqr8vjvy34jhu027zd70ypl0nnev5ewa6r7h" target="_blank">Stake with Synergy Nodes</a>
        </div>
        
        <hr className="myhr"></hr>

        {toolsMenu && (
          <div className="toolBoxMain">  
            <div className="toolBox" onClick={toggleBelow1}>
              Node Snapshot
            </div>
            <div className="toolBox" onClick={toggleBelow8}>
              Node Installation
            </div>            
            <div className="toolBox" onClick={toggleBelow2}>
              Public Endpoints
            </div>           
            <div className="toolBox" onClick={toggleBelow3}>
              Persistent Peers
            </div>  
            <div className="toolBox" onClick={toggleBelow4}>
              AddrBook File
            </div>   
            <div className="toolBox" onClick={toggleBelow5}>
              Genesis File
            </div>  
          </div>
        )}

        {!isBackVisible && (
          <div className="toolBoxMain">
            <div className="toolBoxBack" onClick={toggleBack}>
              Go Back
            </div>
          </div>
        )}   

 
          {isBelowVisible1 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Snapshot</div>
            </div>            
            <div className="below">
              <div className="snap">
                <b>Type:</b> Pruned Snapshot <br />
                <b>Version:</b> elysd {version} <br />
                <b>Block:</b> {block} <br />
                <b>Size:</b> {size} <br />
                <b>Timestamp:</b> {timestamp} Hours ago <br />
              </div>
            
              <br></br>
              <b>Follow these commands</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {snapOne}
              </SyntaxHighlighter>           

            </div>
            </div>
          )}






          
          {isBelowVisible8 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Node Installation</div>
            </div>            
            <div className="below">
              
              <br />
              <b>Login as User and install required tools</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallUser}
              </SyntaxHighlighter>              
              
              <br />
              <b>Install Go</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallGo}
              </SyntaxHighlighter>

              <br />
              <b>Install the Node</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallNode}
              </SyntaxHighlighter>

              <br />
              <b>Initialize the Node</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallInitialize}
              </SyntaxHighlighter>              
              
              <br />
              <b>Download Genesis file</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallGenesis}
              </SyntaxHighlighter>    
              
              <br />
              <b>Download Addrbook file</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallAddrbook}
              </SyntaxHighlighter>    
              
              <br />
              <b>Add / Update Persistent Peers</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallPeers}
              </SyntaxHighlighter> 
              
              <br />
              <b>Download & decompress Snapshot</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallSnapshot}
              </SyntaxHighlighter>                 
              
              <br />
              <b>Create Service File</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallService}
              </SyntaxHighlighter>     
              
              <br />
              <b>Start the Node</b>
              <SyntaxHighlighter language="shell" style={customDarcula}>
                {nodeInstallStart}
              </SyntaxHighlighter>                 

            </div>
            </div>
          )}




          
          {isBelowVisible2 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Public Endpoints</div>
            </div>
              <div className="below"><a href="https://elys-test-api.synergynodes.com">https://elys-test-api.synergynodes.com</a></div>
              <div className="belowtext">
              <a href="https://elys-test-rpc.synergynodes.com">https://elys-test-rpc.synergynodes.com</a>
              </div>            
            </div>
          )}





          
          {isBelowVisible3 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Persistent Peers</div>
            </div>
              <div className="below">
                The persistent peers list is updated every day. Here is the list
                of persistent peers.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {peersOne}
                </SyntaxHighlighter>
                You can use the following to update the peers in config.toml file.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                    {peersTwo}
                </SyntaxHighlighter>
              </div>
            </div>
          )}



          {isBelowVisible4 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Addrbook File</div>
            </div>
              <div className="below">
              The Addrbook is updated once every week. Stop the node, download addrbook.json file, replace it and restart the node.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                  {addrbook}
                </SyntaxHighlighter>
              </div>
            </div>
          )}

        


          {isBelowVisible5 && (
            <div className="node-box" style={{ height: "100%" }}>
            <div className="upper">
              <div className="right">Genesis File</div>
            </div>
              <div className="below">
              We recommend you to download the genesis.json file from Elys official Github page. However, we also provide the same copy of the genesis.json file. You can download and replace the genesis.json file using the following commands.
                <SyntaxHighlighter language="shell" style={customDarcula}>
                  {genesis}
                </SyntaxHighlighter>
              </div>
            </div>
          )} 





      </div>
    </div>
    <Footer />
    </>
  );
};

export default ServiceElystest;
